import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { pipe, path, map, filter } from 'ramda'
import { MDXProvider } from '@mdx-js/react'
import { format } from 'date-fns'

import ContentRightSidebar from '../../components/Layout/ContentRightSidebar/ContentRightSidebar'
import {
  Divider,
  Calendar,
  TransitionLink,
  PressOfficeWidget,
  PinBlockGroup,
  AggregatorPin4,
  TemplateBreadCrumbs,
} from '../../components'
import styles from './PastEventsPageTemplate.module.css'

const propTypes = {
  data: PropTypes.shape({
    headerImage: PropTypes.shape({
      childCloudinaryMediaImage: PropTypes.shape({
        fluid: PropTypes.any,
      }),
    }),
    events: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            fields: PropTypes.arrayOf(
              PropTypes.shape({
                slug: PropTypes.string,
              }),
            ),
            frontmatter: PropTypes.arrayOf(
              PropTypes.shape({
                title: PropTypes.string,
                startDate: PropTypes.string,
                city: PropTypes.string,
                featuredImage: PropTypes.string,
              }),
            ),
          }),
        }),
      ),
    }),
  }),
}

const eventDates = pipe(
  map(edge => {
    return new Date(path(['node', 'frontmatter', 'startDate'], edge))
  }),
)

export const PastEventsPageTemplate = ({ data }) => {
  const [activeFilter, setActiveFilter] = useState(null)

  const eventsByActiveFilter = useMemo(
    () =>
      !activeFilter
        ? []
        : pipe(
            filter(
              event =>
                format(
                  new Date(path(['node', 'frontmatter', 'startDate'], event)),
                  'yyyy-MM',
                ) === format(activeFilter, 'yyyy-MM'),
            ),
          )(data.events.edges),
    [activeFilter],
  )

  return (
    <MDXProvider>
      <Divider thickness={4} />
      <Calendar
        dates={eventDates(data.events.edges)}
        onMonthClick={date => setActiveFilter(date)}
      />

      <Divider thickness={4} />

      {activeFilter && (
        <>
          <PinBlockGroup
            sectionTitle={`Events in ${format(activeFilter, 'MMM yyyy')}`}
            columns={2}
          >
            {eventsByActiveFilter.map(({ node }, i) => (
              <AggregatorPin4
                key={i}
                title={node.frontmatter.title}
                thumbnailSrc={node.frontmatter.featuredImage}
                href={node.fields.slug}
              >
                {node.frontmatter.excerpt}...
              </AggregatorPin4>
            ))}
          </PinBlockGroup>
          <Divider thickness={4} />
        </>
      )}

      <div className={styles.moreEvents}>
        <TransitionLink to="/convening" className="button is-primary">
          Current Events
        </TransitionLink>
        <a
          href="https://www.gsb.uct.ac.za/events"
          target="_blank"
          rel="noreferrer"
          className="button is-primary"
        >
          GSB Events
        </a>
      </div>
    </MDXProvider>
  )
}

PastEventsPageTemplate.propTypes = propTypes

const PastEventsPage = ({ data }) => {
  const { headerImage } = data
  const header = {
    title: '',
    subTitle: '',
    backgroundUrl: headerImage,
  }

  const breadcrumbs = [
    {
      label: 'Events',
      url: '/convening',
    },
    {
      label: 'Past Events',
    },
  ]

  return (
    <ContentRightSidebar header={header}>
      <div>
        <TemplateBreadCrumbs breadcrumbs={breadcrumbs} />
        <PastEventsPageTemplate data={data} />
      </div>
      <div>
        <PressOfficeWidget />
      </div>
    </ContentRightSidebar>
  )
}

PastEventsPage.propTypes = propTypes

export default PastEventsPage

export const pageQuery = graphql`
  query PastEventsPageTemplate($currentDate: Date) {
    headerImage: cloudinaryMedia(
      secure_url: {
        eq: "https://res.cloudinary.com/do95jfmcf/image/upload/v1586963670/website/misc/convening-aggregator-2x.png"
      }
    ) {
      childCloudinaryMediaImage {
        fluid {
          aspectRatio
          base64
          sizes
          src
          srcSet
        }
      }
    }
    events: allMdx(
      filter: {
        fields: { contentType: { eq: "events" } }
        frontmatter: { startDate: { lte: $currentDate }, archive: { ne: true } }
      }
      limit: 1000
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            startDate
            city
            excerpt
            featuredImage {
              childCloudinaryMediaImage {
                fixed(width: 150) {
                  base64
                  height
                  src
                  srcSet
                  width
                }
              }
            }
          }
        }
      }
    }
  }
`
